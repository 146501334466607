import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Header from "./UI/Header"
import styled from "styled-components"
import "./index.css"
import Nav from "./UI/Nav"
import { navigate, Link } from "gatsby"
import { useContainer } from "unstated-next"
import { CurrentUserState } from "./state/CurrentUserState"
import { Select, TextInput } from "grommet"
import { AppState } from "./state/AppState"
import { useNavBar } from "./hooks/useNavBar"
import { useApolloClient } from "@apollo/react-hooks"
import gql from "graphql-tag"

const Content = styled.div`
  padding: 0 30px 0 70px;
`

const ChallengeSearch = styled.div`
  margin: 0 20px 20px 20px;
`

const yearGroupOptions = [
  { val: 1, lab: "Year 1" },
  { val: 2, lab: "Year 2" },
  { val: 3, lab: "Year 3" },
  { val: 4, lab: "Year 4" },
  { val: 5, lab: "Year 5" },
  { val: 6, lab: "Year 6" },
]

const yearGroupToLabel = {
  1: "Year 1",
  2: "Year 2",
  3: "Year 3",
  4: "Year 4",
  5: "Year 5",
  6: "Year 6",
}

const Layout = ({ children }) => {
  const state = CurrentUserState.useContainer()
  const {
    yearGroup,
    selectYearGroup,
    selectedCurriculumGroup,
    selectCurriculumGroup,
  } = useContainer(AppState)
  const [curriculumGroups, setCurriculumGroups] = useState([])
  const client = useApolloClient()
  const { close } = useNavBar()

  useEffect(() => {
    if (typeof window === "undefined") return
    init()
    const yearGroup = localStorage.getItem("selectedYearGroup")
    if (yearGroup) {
      selectYearGroup(parseInt(yearGroup))
    }
    const selectedCurriculumGroup = localStorage.getItem("selectedCurriculumGroup")
    if (selectedCurriculumGroup) {
      selectCurriculumGroup(JSON.parse(selectedCurriculumGroup))
    }
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return
    localStorage.setItem("selectedYearGroup", yearGroup)
  }, [yearGroup])

  useEffect(() => {
    if (typeof window === "undefined") return
    localStorage.setItem("selectedCurriculumGroup", JSON.stringify(selectedCurriculumGroup))
  }, [selectedCurriculumGroup])

  useEffect(() => {
    if (state.isAuthenticated === false) {
      navigate("/login")
    }
  }, [state.isAuthenticated])

  const init = async () => {
    const { data } = await client.query({
      query: gql`
        query getCurriculumGroups {
          curriculumGroups {
            _id
            title
          }
        }
      `,
    })
    setCurriculumGroups(data.curriculumGroups)
  }

  if (!state.isAuthenticated) return null

  return (
    <>
      <Header>
        <Select
          options={curriculumGroups.map(d => ({ _id: d._id, title: d.title }))}
          value={{
            _id: selectedCurriculumGroup._id,
            title: selectedCurriculumGroup.title,
          }}
          labelKey="title"
          valueKey="_id"
          onChange={({ value }) => selectCurriculumGroup(value)}
        />
        <Select
          options={yearGroupOptions}
          value={{ val: yearGroup, lab: yearGroupToLabel[yearGroup] }}
          labelKey="lab"
          valueKey="val"
          onChange={({ value }) => selectYearGroup(value.val)}
        />
      </Header>
      <Nav>
        <ChallengeSearch>
          <TextInput
            placeholder="Challenge URL"
            onKeyPress={e =>
              e.which === 13
                ? navigate(
                    `/challenges${
                      e.target.value.split("edu/zoo/curriculum")[1]
                    }`
                  )
                : null
            }
          />
        </ChallengeSearch>
        <Link to="/curriculum-editor" onClick={close}>
          Curriculum Editor
        </Link>
        <Link to="/learning-goals" onClick={close}>
          Learning Goals
        </Link>
        <Link to="/curriculum-groups" onClick={close}>
          Curriculum Groups
        </Link>
      </Nav>
      <Content>{children}</Content>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
