import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../theme'
import { Previous, Next } from 'grommet-icons'
import { useSpring, animated } from 'react-spring'
import { useNavBar } from '../hooks/useNavBar'
import { useContainer } from 'unstated-next'
import { CurrentUserState } from '../state/CurrentUserState'

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 50px;
  height: calc(100vh - 50px);
  width: 300px;
  background-color: #fff;
  z-index: 10;
`

const ToggleButton = styled.div`
  position: absolute;
  top: 0;
  right: -50px;
  width: 50px;
  height: 50px;
  background-color: ${colors.indigo};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 8px 0;
  cursor: pointer;
`

const Content = styled.div`
  padding: 30px 0;
  a {
    display: block;
    color: inherit;
    padding: 0 20px;
    text-decoration: none;
    height: 30px;
    margin-bottom: 20px;
  }
`

const Nav = ({ children }) => {
  const { toggle, isOpen } = useNavBar()
  const state = useContainer(CurrentUserState)

  state.login()
  const openSpring = useSpring({ x: [ isOpen ? 0 : -300 ] })

  return <Wrapper style={{
    transform: openSpring.x.interpolate(
      x => `translateX(${x}px)`
    )
  }}>
    <ToggleButton onClick={toggle}>
      { isOpen ? <Previous color='#fff' /> : <Next color='#fff' /> }
    </ToggleButton>
    <Content>
      { children }
    </Content>
  </Wrapper>
}

Nav.propTypes = {
  children: PropTypes.node
}

Nav.defaultProps = {
}

export default Nav
