import { useContainer } from 'unstated-next'
import { AppState } from '../state/AppState'

export const useNavBar = () => {
  const state = useContainer(AppState)

  const close = () => {
    state.setNavBarOpened(false)
  }

  const open = () => {
    state.setNavBarOpened(true)
  }

  const toggle = () => {
    state.setNavBarOpened(!state.navBarOpened)
  }

  return {
    isOpen: state.navBarOpened,
    close,
    open,
    toggle
  }
}
